<template>
    <div class="stream-tier-feed">
        <StreamTier v-for="tier in processedTierStream.stream" v-bind="tier" :key="tier.id" />
        <Tier class="footnotes-tier" isCenteredContent v-if="processedTierStream.feedFootnotes.length">
            <FootnotesList :footnotes="processedTierStream.feedFootnotes" />
        </Tier>
    </div>
</template>

<script setup>
const props = defineProps({
    streamTiered: {
        type: Array,
    },
});

const processFootnotes = (streamTiered) => {
    const feedFootnotes = [];
    let lastTierFootnoteCount = 0;

    if (!streamTiered) {
        return { stream: [], feedFootnotes };
    }

    const clonedTierStream = JSON.parse(JSON.stringify(streamTiered));

    clonedTierStream.forEach((tier) => {
        const { processedBlocks, footnotes, footnoteCount } = getStreamBlocksWithFootnotes(
            tier.blocks,
            lastTierFootnoteCount,
        );
        lastTierFootnoteCount += footnoteCount;
        tier.blocks = processedBlocks;
        footnotes.forEach((note) => {
            feedFootnotes.push(note);
        });
    });
    return { stream: clonedTierStream, feedFootnotes };
};

const processedTierStream = computed(() => processFootnotes(props.streamTiered));
</script>

<style lang="scss" scoped></style>
